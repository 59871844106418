import axios from "axios";
import store from "../store";
var BASEURL = process.env.NODE_ENV === "development" ? '' : window.global_config.BASE_URL

//创建axios
const instance = axios.create({
    baseURL: BASEURL,
    timeout: 10000
});

// 添加请求拦截器
instance.interceptors.request.use(function (config) {
    // console.log(config.headers.Authorization)
    // 在发送请求之前做些什么
    if (store.state.login.user && (typeof config.headers.Authorization == 'undefined' || config.headers.Authorization == '')) {
        config.headers.Authorization = store.state.login.token
    }
    // console.log(config.headers.Authorization)

    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
instance.interceptors.response.use(
    function (response) {
        if (response.status == 200) {
            if (response.config.responseType === 'blob') {
                const fileName = decodeURI(
                    response.headers['content-disposition'].split('filename*=UTF-8\'\'')[1]
                )
                return Promise.resolve({ data: response.data, fileName: fileName })
            }
            let data = response.data
            if (data.Code == 200)
                return data;
            else if (data.code == 401) {
                // token无效，这里跳转登录
                setTimeout(() => {
                    window.location.href = '/';
                }, 1000)
            }
            else if (data.code == 402) {//token刷新
                store.dispatch('login/refreshToken', data.Message)
                return Promise.reject('Token刷新');
            }
            else {
                return Promise.reject(data.Msg);
            }
        }
        else {
            return Promise.reject('服务器响应异常');
        }
    }, function (error) {
        // 对响应错误做点什么
        if (error.response) {
            return Promise.reject('服务器请求异常');
        }
        else if (error.code === 'ECONNABORTED' || error.message === "Network Error" || error.message.includes("timeout")) {
            return Promise.reject('服务器请求超时');
        }
        else {
            return Promise.reject('服务器异常');
        }
    });
export default instance
