<template>
  <router-view />
  <div class="copyright">
    © {{ year }} 唐山康新新能源科技有限公司
    <a href="https://beian.miit.gov.cn/ ">冀ICP备2022016447号-2</a>
  </div>
</template>
<script setup>
import { ref } from 'vue'
const year = ref(new Date().getFullYear())
</script>
<style lang="scss">
* {
  font-family: Arial, Helvetica, sans-serif;
  margin: 0;
  padding: 0;
  background-repeat: no-repeat;
  background-size: cover;
  line-height: 1.15;
}
.copyright {
  position: absolute;
  bottom: 5px;
  width: 100%;
  margin: 0 auto;
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
  font-size: 10px;
  a {
    color: rgba(255, 255, 255, 0.5);
    text-decoration: none;
  }
}
</style>
