const tokenKey = 'tokenInfo'
const userKey = 'userInfo'
const menuKey = 'menuInfo'
const rememberKey = 'rememberInfo'

export function getToken() {
    return localStorage.getItem(tokenKey);
}
export function setToken(token) {
    localStorage.setItem(tokenKey, token)
}
export function removeToken() {
    localStorage.removeItem(tokenKey)
}

export function getUser() {
    if (localStorage.getItem(userKey)) {
        return localStorage.getItem(userKey);
    }
    else {
        return JSON.stringify({})
    }
}
export function setUser(value) {
    localStorage.setItem(userKey, value)
}
export function removeUser() {
    localStorage.removeItem(userKey)
}
export function getMenus() {
    if (localStorage.getItem(menuKey)) {
        return localStorage.getItem(menuKey);
    }
    else {
        return null
    }
}
export function setMenus(value) {
    localStorage.setItem(menuKey, value)
}
export function removeMenus() {
    localStorage.removeItem(menuKey)
}
export function getRememberInfo() {
    if (localStorage.getItem(rememberKey)) {
        return localStorage.getItem(rememberKey);
    }
    else {
        return JSON.stringify({
            rememberPass: false,
            account: '',
            passWord: ''
        })
    }
}
export function setRememberInfo(value) {
    localStorage.setItem(rememberKey, value)
}
export function removeRememberInfo() {
    localStorage.removeItem(rememberKey)
}