import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('../views/Login/index.vue')
  },
  {
    path: '/GraphDesign',
    name: 'GraphDesign',
    component: () => import('@/views/Graph/GraphDesign/index.vue')
  },
  {
    path: '/GraphPreview',
    name: 'GraphPreview',
    component: () => import('@/views/Graph/GraphPreview/index.vue')
  },
  {
    path: '/home',
    name: 'Home',
    redirect: 'cockpit',
    component: () => import('../layout/index.vue'),
    children: [
      {
        path: '/cockpit',
        name: 'cockpit',
        component: () => import('../views/Cockpit/index.vue')
      }
    ]
  },
  {
    path: '/dataMonitor',
    name: 'DataMonitor',
    redirect: 'enterpriseCustom',
    component: () => import('../layout/index.vue'),
    children: [
      {
        path: '/realTimeData',
        name: 'realTimeData',
        component: () => import('../views/DataMonitor/RealTimeData/index.vue')
      },
      {
        path: '/historyData',
        name: 'historyData',
        component: () => import('../views/DataMonitor/HistoryData/index.vue')
      },
      {
        path: '/alarmData',
        name: 'alarmData',
        component: () => import('../views/DataMonitor/AlarmData/index.vue')
      },
      {
        path: '/faultData',
        name: 'faultData',
        component: () => import('../views/DataMonitor/FaultData/index.vue')
      },
      {
        path: '/repairData',
        name: 'repairData',
        component: () => import('../views/DataMonitor/RepairData/index.vue')
      }
    ]
  },
  {
    path: '/scada',
    name: 'Scada',
    redirect: 'sceneManage',
    component: () => import('../layout/index.vue'),
    children: [
      {
        path: '/sceneManage',
        name: 'sceneManage',
        component: () => import('../views/ScadaManage/SceneManage/index.vue')
      },
      {
        path: '/nodeGroupManage',
        name: 'nodeGroupManage',
        component: () => import('../views/ScadaManage/NodeGroupManage/index.vue')
      },
      {
        path: '/nodeManage',
        name: 'nodeManage',
        component: () => import('../views/ScadaManage/NodeManage/index.vue')
      },
      {
        path: '/bgImgManage',
        name: 'bgImgManage',
        component: () => import('../views/ScadaManage/BgImgManage/index.vue')
      }
    ]
  },
  {
    path: '/trendAnalysis',
    name: 'TrendAnalysis',
    redirect: 'dataTrends',
    component: () => import('../layout/index.vue'),
    children: [
      {
        path: '/dataTrends',
        name: 'dataTrends',
        component: () => import('../views/TrendAnalysis/DataTrends/index.vue')
      },
      {
        path: '/alarmTrends',
        name: 'alarmTrends',
        component: () => import('../views/TrendAnalysis/AlarmTrends/index.vue')
      }
    ]
  },
  {
    path: '/custom',
    name: 'Custom',
    redirect: 'enterpriseCustom',
    component: () => import('../layout/index.vue'),
    children: [
      {
        path: '/enterpriseCustom',
        name: 'enterpriseCustom',
        component: () => import('../views/CustomManage/EnterpriseCustom/index.vue')
      },
      {
        path: '/personalCustom',
        name: 'personalCustom',
        component: () => import('../views/CustomManage/PersonalCustom/index.vue')
      },
      {
        path: '/payRecord',
        name: 'payRecord',
        component: () => import('../views/CustomManage/PayRecord/index.vue')
      },
      {
        path: '/useDay',
        name: 'useDay',
        component: () => import('../views/CustomManage/UseDay/index.vue')
      },
      {
        path: '/smsRecord',
        name: 'smsRecord',
        component: () => import('../views/CustomManage/SMSRecord/index.vue')
      }
    ]
  },
  {
    path: '/device',
    name: 'Device',
    redirect: 'deviceInfo',
    component: () => import('../layout/index.vue'),
    children: [
      {
        path: '/deviceInfo',
        name: 'deviceInfo',
        component: () => import('../views/DeviceManage/DeviceInfo/index.vue')
      },
      {
        path: '/deviceType',
        name: 'deviceType',
        component: () => import('../views/DeviceManage/DeviceType/index.vue')
      },
      {
        path: '/deviceParame',
        name: 'deviceParame',
        component: () => import('../views/DeviceManage/DeviceParame/index.vue')
      },
      {
        path: '/deviceControl',
        name: 'deviceControl',
        component: () => import('../views/DeviceManage/DeviceControl/index.vue')
      }
    ]
  },
  {
    path: '/system',
    name: 'System',
    redirect: 'userManage',
    component: () => import('../layout/index.vue'),
    children: [
      {
        path: '/userManage',
        name: 'userManage',
        component: () => import('../views/SystemManage/UserManage/index.vue')
      },
      {
        path: '/operationLog',
        name: 'operationLog',
        component: () => import('../views/SystemManage/OperationLog/index.vue')
      },
      {
        path: '/loginInfo',
        name: 'loginInfo',
        component: () => import('../views/SystemManage/LoginInfo/index.vue')
      },
      {
        path: '/connectLog',
        name: 'connectLog',
        component: () => import('../views/SystemManage/ConnectLog/index.vue')
      },
      {
        path: '/roleManage',
        name: 'roleManage',
        component: () => import('../views/SystemManage/RoleManage/index.vue')
      },
      {
        path: '/parameManage',
        name: 'parameManage',
        component: () => import('../views/SystemManage/ParameManage/index.vue')
      }
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
