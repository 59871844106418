import { login } from '@/api/Auth'
import { setToken, setUser, getUser, getToken, removeToken, removeUser, getMenus, removeMenus, setMenus, getRememberInfo, setRememberInfo } from '@/utitls/app'
const state = {
    token: getToken() || '',
    user: JSON.parse(getUser()) || {},
    menus: getMenus() || [],
    rememberInfo: JSON.parse(getRememberInfo()) || {
        rememberPass: false,
        account: '',
        passWord: ''
    }
}
const getters = {
    username: state => state.user.Name
}
const mutations = {
    SET_TOKEN(state, value) {
        state.token = value
    },
    SET_USER(state, value) {
        state.user = value
    },
    SET_Menu(state, value) {
        state.menus = value
    },
    SET_RememberInfo(state, value) {
        state.rememberInfo = value
    }
}
const actions = {
    userLogin({ commit }, requestData) {
        return new Promise((resolve, reject) => {
            login(requestData).then((res) => {
                if (res) {
                    const newToken = 'Bearer ' + res.Token
                    commit('SET_TOKEN', newToken)
                    commit('SET_USER', res.Data)
                    commit('SET_Menu', res.Menus)
                    setToken(newToken)
                    setUser(JSON.stringify(res.Data))
                    setMenus(res.Menus)
                    resolve(res)
                }
                else {
                    reject(res)
                }
            }).catch(err => {
                reject(err)
            })
        });
    },
    userLogout({ commit }) {
        commit('SET_TOKEN', '')
        commit('SET_USER', {})
        commit('SET_Menu', [])
        removeToken()
        removeUser()
        removeMenus()
    },
    refreshToken({ commit }, token) {
        const newToken = 'Bearer ' + token
        commit('SET_TOKEN', newToken)
        setToken(newToken)
    },
    setRememberInfos({ commit }, newRememberInfo) {
        commit('SET_RememberInfo', JSON.parse(newRememberInfo))
        setRememberInfo(newRememberInfo)
    }
}
export default { namespaced: true, state, getters, mutations, actions };
