import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/dist/index.css'
import * as ElIcons from '@element-plus/icons-vue'
import './assets/iconfont/iconfont.css'
import './styles/common.scss'
import 'default-passive-events'

const app = createApp(App)
for (const iconName in ElIcons) {
    app.component(iconName, ElIcons[iconName])
}
app.use(ElementPlus, {
    locale: zhCn,
}).use(store).use(router).mount('#app')