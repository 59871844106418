import $http from '@/utitls/request'

/**
 * 登录
 */
export const login = (data) => {
    return $http.post('/api/Auth', data)
}
/**
 * 修改密码
 */
export const editPass = (data) => {
    return $http.put('/api/Auth', data)
}
/**
 * 退出
 */
export const LoginOut = (data) => {
    return $http.delete('/api/Auth', { params: data })
}
